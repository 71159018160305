<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="isManagerRW">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'companies'}"><a>{{$t('breadcrumb_companies')}}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'accounts-org', params: {cid, oid: 'default'}}"><a>{{$t('breadcrumb_accounts')}}</a></router-link>
            <router-link tag="li" :to="{name: 'account', params: {cid, aid}}"><a><account-name :account="account"/></a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-show="aLoading" />
    <!-- prettier-ignore -->
    <section class="section py-4" v-if="account">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-tablet is-9-desktop">
            <h1 class="title is-2 pl-2">
              <account-name :account="account"/>
            </h1>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-12-mobile is-4-tablet is-3-desktop" v-if="isEmployee">
            <div class="links">
              <router-link :to="{name: 'sheets-year', params: {...params, year}}">
                <span>{{$t('account_sheets')}}</span>
                <span class="help text-gray-800">{{$t('account_sheets_help')}}</span>
              </router-link>
              <router-link :to="{name: 'events', params}">
                <span>{{$t('account_events')}}</span>
                <span class="help text-gray-800">{{$t('account_events_help')}}</span>
              </router-link>
              <router-link :to="{name: 'rights', params}">
                <span>{{$t('account_rights')}}</span>
                <span class="help text-gray-800">{{$t('account_rights_help')}}</span>
              </router-link>
            </div>
          </div>
          <div class="column is-12-mobile is-4-tablet is-3-desktop" v-if="isEmployee">
            <div class="links">
              <router-link :to="{name: 'report-work', params}">
                <span>{{$t('account_report_work')}}</span>
                <span class="help text-gray-800">{{$t('account_report_work_help')}}</span>
              </router-link>
              <router-link :to="{name: 'report-vacations', params}">
                <span>{{$t('account_report_vacations')}}</span>
                <span class="help text-gray-800">{{$t('account_report_vacations_help')}}</span>
              </router-link>
              <router-link :to="{name: 'report-passages', params}">
                <span>{{$t('account_report_passages')}}</span>
                <span class="help text-gray-800">{{$t('account_report_passages_help')}}</span>
              </router-link>
            </div>
          </div>
          <div class="column is-12-mobile is-4-tablet  is-3-desktop">
            <div class="links">
              <router-link :to="{name: 'settings', params}">
                <span>{{$t('account_settings')}}</span>
                <span class="help text-gray-800">{{$t('account_settings_help')}}</span>
              </router-link>
              <router-link :to="{name: 'permissions', params}" v-if="isManagerRW">
                <span>{{$t('account_permissions')}}</span>
                <span class="help text-gray-800">{{$t('account_permissions_help')}}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.links a {
  @apply block p-2;
}
.links a:hover {
  @apply bg-gray-200;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { contains } from 'ramda';
import account from './account';
import SqrProgress from '@/sqrd/SqrProgress';
import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
export default {
  name: 'Account',
  mixins: [account],
  components: { SqrProgress, CompanyName, AccountName },
  computed: {
    ...mapGetters('perms', ['isManagerRW']),
    isEmployee() {
      return contains(this.aid, this.company?.employeeUids ?? []);
    },
    params() {
      return {
        cid: this.cid,
        aid: this.aid,
      };
    },
    year() {
      return String(new Date().getFullYear());
    },
  },
};
</script>
